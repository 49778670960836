.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*margin-top: 20px;*/
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f4f4f4;
}

.leftBlock {
    width: 30%;
    display: flex;
    justify-content: center;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    width: 95%;
}

.img {
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.rightBlock {
    width: 40%;
    display: flex;
    justify-content: center;
    height: 100%;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    /*background-color: #D9D9D9;*/
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    width: 95%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.title {
    font-weight: 600;
    font-size: 28px;
    text-transform: uppercase;
    margin: 10px;
    text-align: center;
}

.description {
    margin: 10px;
    font-weight: 300;
}

.info {
    font-size: 16px;
    margin-top: 5px;
    margin-left: 10px;
}


@media screen and (max-width: 820px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .leftBlock {
        width: 95%;
    }

    .rightBlock {
        width: 95%;
        margin-top: 20px;
    }
}
@media screen and (max-width: 475px) {
    .title {
        font-size: 20px;
        margin: 6px;
    }
    .description {
        margin: 6px;
        font-size: 16px;
    }
    .info {
        font-size: 12px;
        margin-left: 6px;
    }
}