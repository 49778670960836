.counterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*margin-top: 15px;*/
}

.counter {
    display: flex;
    align-items: center;
    /*border: 1px solid red;*/
}
.icon {
    width: 30px;
    height: 30px;
    margin: 6px;
    stroke: #000000;
}
.icon:hover {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.counterInput {
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 36px;
    height: 36px;
    padding: 0;

    font-size: 17px;
    text-align: center;

    border: 1px solid #D9D9D9;
    border-radius: 4px;
    outline: none;
}

.counterInput::-webkit-outer-spin-button,
.counterInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
@media screen and (max-width: 685px){
    .counterInput {
        width: 22px;
        height: 22px;
        font-size: 10px;
    }
    .counter {
        display: flex;
        justify-content: center;
        /*border: 1px solid red;*/
    }
    .counterContainer {
    margin: 0;
    }
    .icon {
        width: 20px;
        height: 20px;
        margin: 4px;

    }
}