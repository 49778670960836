.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0 31px 0;

}

.rememberMeWrapper {
    display: flex;

    width: 100%;
}

.label {
    margin-bottom: 28px;
}

.forgotPasswordWrapper {
    display: flex;
    justify-content: flex-end;

    width: 100%;
}

.linkFPWD {
    cursor: pointer;

    margin: 0 0 20px 0;
}

.linkFPWD:hover {
    font-weight: bold;
}

.linkSUP {
    cursor: pointer;

    margin-top: 11px;

    color: #366eff;
    text-decoration: underline;
}

.linkSUP:hover {
    font-weight: bold;
}

.errorMsg{
    color: red;
    font-size: 12px;
}