.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #f4f4f4;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.container {
   width: 80%;
    display: flex;
    align-items: center;
}

.containerTwo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    gap: 20px;

    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 599px){
    .containerTwo {
        align-content: flex-start;
    }
}