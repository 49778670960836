.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkbox {
    width: 100%;
}

.errorMsg{
    color: red;
    font-size: 12px;
}