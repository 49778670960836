.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.linksContainer {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.link {
    cursor: pointer;
    margin: 20px;
    font-size: 25px;
    font-weight: 600;
}

.activeLink {
    cursor: pointer;
    margin: 20px;
    font-size: 25px;
    font-weight: 600;
    color: olivedrab;

}

.link:hover {
    color: olivedrab;
}
@media screen and (max-width: 650px){
    .link {
        margin: 10px;
        font-size: 14px;
        /*font-weight: 600;*/
    }

    .activeLink {
        margin: 10px;
        font-size: 14px;
        /*font-weight: 600;*/

    }

}