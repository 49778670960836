.wrapper {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    /*width: 100%;*/
    /*min-height: 100vh;*/
    /*justify-content: center;*/
    align-items: center;
    margin: 20px;
}

.title{
    margin-bottom: 20px;
}