.wrapper {
    /*min-height: 100vh;*/
    /*min-height: 65vh;*/
    display: flex;
    /*width: 100%;*/
    /*min-height: 100vh;*/
    justify-content: center;
}
.infoContainer {
    display: flex;
    flex-direction: column;
    /*background-color: #D9D9D9;*/

    border-radius: 10px;
    margin: 20px;
    /*border: 1px solid red;*/
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

}
.countContainer {
    display: flex;
    flex-direction: column;
    /*background-color: #D9D9D9;*/
    border-radius: 10px;

    align-items: center;
    margin: 20px;
    /*border: 1px solid red;*/
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);


}
.wrapTit {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    /*border: 1px solid red;*/

}
.wrapBtns {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/

    /*border: 1px solid red;*/

}
/*.mainTitle {*/
/*    font-weight: 800;*/
/*    font-size: 40px;*/
/*    margin: 20px;*/
/*    border-bottom: 4px solid #A26B2A*/
/*}*/
.mainTitle {
    background-color: var(--secondary-color);
    background-image: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-weight: 800;
    font-size: 40px;
    margin: 20px;
    border-bottom: 4px solid #A26B2A;
}

.totalPrice {
    font-size: 22px;
    margin: 25px;
}
.myOrders {
    cursor: pointer;
    margin-bottom: 20px;
}
.myOrders:hover {
    font-weight: 600;
}

@media screen and (max-width: 940px){
    .wrapper {
        flex-direction: column;
        /*min-height: 70vh;*/
    }
    .countContainer {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        /*justify-content: center;*/
        gap: 16px;

    }
    .wrapTit {
        /*align-items: flex-start;*/
    }
    .wrapBtns {
        /*align-items: flex-start;*/
    }
    .myOrders {
    margin-right: 15px;
    }
}
/*@media screen and (max-width: 685px) {*/
@media screen and (max-width: 599px) {
    .wrapper {
        /*min-height: 40vh;*/
    }
    .mainTitle {
        font-size: 20px;
        margin: 8px;
        border-bottom: 2px solid #A26B2A
    }
    .infoContainer {
        margin: 8px;
    }
    .countContainer {
        margin: 8px;
    }
    .totalPrice {
        font-size: 14px;
        margin: 10px;
    }
    .myOrders {
        font-size: 14px;
    }
}