.wrapper {
    display: flex;
    gap: 15px;
}
@media screen and (max-width: 650px){
    .wrapper {
        flex-direction: column;
        justify-content: center;
        gap: 6px;
    }
}