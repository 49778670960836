.title {
    text-transform: uppercase;
}
.description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* количество строк, которые будут видны */
    -webkit-box-orient: vertical;
}
.subContainer {
    padding: 16px;
}
.sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.counter {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
@media screen and (max-width: 599px){
    .subContainer {
        padding: 6px;
    }
    .counter {
        margin-top: 4px;
        flex-direction: column;
    }
}