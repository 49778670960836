.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0 31px 0;
}

.link {
    cursor: pointer;

    margin-top: 11px;

    color: #366eff;
    text-decoration: underline;
}

.link:hover {
    font-weight: bold;
}

.errorMsg{
    color: red;
    font-size: 12px;
}