.formWrapper {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/

    font-weight: 300;
    font-size: 18px;
}
.details {
    cursor: pointer;
}
.details:hover {
    font-weight: 600;
}