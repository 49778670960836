.logoContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoContainer:hover > span:nth-child(2) {
    font-weight: 600;
}

.logoContainer:hover > img:nth-child(1) {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
}

.logoSm {
    border-radius: 100px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.cart {
    width: 45px;
    height: 45px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.cart:hover {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    /*box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);*/
}

.burgAndCartMinContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.burgerIcon {
    width: 45px;
    height: 45px;
    /*fill: var(--secondary-color);*/
    /*stroke: var(--secondary-color);*/
    stroke: #000000;
}

.burgerIcon:hover {
    cursor: pointer;

    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    /*box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);*/
}
.capIcon {
    width: 25px;
    height: 25px;
    fill: #000000;
    margin-right: 14px;
}

.capIcon:hover {
    cursor: pointer;
    fill: var(--secondary-color)
}

.navItem {
    display: flex;
    align-items: center;
}

.navItem:hover {
    font-weight: 600;
    cursor: pointer;
}


/*@media (min-width: 768px) {*/
@media (min-width: 951px) {
    /* Стили для широких экранов */
    .drawer {
        display: none;
    }

    /*.burgerIcon {*/
    /*    display: none;*/
    /*}*/
    .burgAndCartMinContainer {
        display: none;
    }
}

/*@media (max-width: 767px) {*/
@media (max-width: 950px) {
    .capIcon {
        margin-right: 0;
    }
    /* Стили для узких экранов */
    .navItem {
        display: none;
    }

    .drawer {
        display: block;
        width: 80vw;
        max-width: 300px;
        height: 100%;
        background-color: white;
        padding: 20px;
    }

    .drawerHeader {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .drawerLinks {
        display: flex;
        flex-direction: column;
        gap: 10px;


        text-transform: uppercase;
        align-items: center;
        font-size: 24px;
        font-weight: 200
    }

    .drawerLink:hover {
        font-weight: 600;
        cursor: pointer;
    }
}