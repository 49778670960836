.text {
    color: coral;
    text-decoration: underline;
}

.btnWrapper {
    display: flex;
    justify-content: center;

    margin-top: 32px;
}