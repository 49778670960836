.wrapper {
}

.container {
    display: flex;
    margin: 10px 20px 10px 20px;
    font-size: 22px;

    background-color: #fff;
    border-radius: 10px;

    align-items: center;
}

.img {
    /*border: 1px solid red;*/

    border-radius: 10px;
    display: block;
    /*margin: auto;*/
    width: 50px;
    height: 50px;
    /*object-fit: contain;*/
    object-fit: cover;
    object-position: center;

    margin: 6px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    margin: 6px;
    height: 100%;


    /*border: 1px solid red;*/
}

.title {
    font-size: 20px;

}

.title::first-letter {
    text-transform: uppercase;
}

.weightPeople {
    font-weight: 300;
    font-size: 17px;

}

.priceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /*border: 1px solid red;*/
    margin: 6px;

    gap: 10px;

    height: 100%;

}

.price {
    font-weight: 300;
}

.count {
    border-bottom: 2px solid #F0E56F;
}

.delete {
    /*border: 1px solid red;*/

}
.icon {
    width: 35px;
    height: 33px;
    margin: 6px;
    /*stroke: #000000;*/
    stroke: #363636;
    fill: #363636;
    /*border: 1px solid red;*/
}
.icon:hover {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;

}

@media screen and (max-width: 940px){
    .container {
    justify-content: space-between;
    }
    .titleContainer {
        width: 300px;
        /*border: 1px solid red;*/
    }
}
/*@media screen and (max-width: 685px){*/
@media screen and (max-width: 599px){
    .container {
        margin: 6px 6px 6px 6px;
    }
    .img {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        margin: 4px;
    }
    .titleContainer {
        width: 150px;
        margin: 4px;
    }
    .title {
        font-size: 10px;
    }
    .weightPeople {
        font-size: 6px;
    }
    .price {
        font-size: 8px;
    }
    .count {
        font-size: 8px;
    }
    .icon {
        width: 20px;
        height: 19px;
        margin: 4px;
    }
    .priceContainer {
        gap: 4px;
        margin: 4px;
    }
}