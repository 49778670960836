.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*align-items: flex-end;*/
    align-items: flex-start;
}

.textBox {
    width: 300px;
    margin: 5px;

}

@media  screen and (max-width: 820px) {
 .container {
     flex-direction: column;
     justify-content: center;
     align-items: center;
     font-size: 10px;
 }
    .textBox {
        margin: 3px;
        text-align: center;
    }
    .mid{
        margin-top: 10px;
        margin-bottom: 10px;
    }

}