.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.wrp {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #f4f4f4;
}

.leftBlock {
    width: 25%;
    display: flex;
    justify-content: center;

    /*border: 1px solid red;*/
}

.leftContainer {
    display: flex;
    flex-direction: column;
    width: 95%;

    /*border: 1px solid green;*/
}

.peopleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #D9D9D9;
    border-radius: 10px;
    margin: 10px;
    padding: 15px;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.peopleTitle {
    text-transform: uppercase;
    padding: 8px;
}

.peopleSubText {
    margin: 10px;
}

.searchMultiple {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.rightBlock {
    width: 75%;
    display: flex;
    justify-content: center;

    /*border: 1px solid red;*/
}

.rightContainer {
    display: flex;
    flex-direction: column;
    width: 95%;

    /*border: 1px solid green;*/
}

.subcategories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 10px;
}

.products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    /*justify-content: center;*/
    gap: 20px;
    margin-top: 20px;
}

.pagination {
    /*width: 100%;*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

@media screen and (max-width: 1324px) {
    .products {
        /*display: flex;*/
        /*flex-direction: row;*/
        /*flex-wrap: wrap;*/
        /*align-content: flex-start;*/
        justify-content: center;
        /*gap: 20px;*/
        /*margin-top: 20px;*/
    }

}

@media screen and (max-width: 1050px) {
    .wrp {
        flex-direction: column;
        /*margin-top: 12px;*/
    }

    .leftBlock {
        width: 100%;
    }

    .rightBlock {
        width: 100%;
    }

    .leftContainer {
        flex-direction: row;
        /*flex-direction: row-reverse;*/
        justify-content: center;
        flex-wrap: wrap;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*width: 95%;*/
    }

    .peopleSubText {
        /*width: 120px;*/
        /*font-size: 14px;*/
    }

    .peopleWrapper {
        font-size: 14px;
        padding: 8px;
        justify-content: flex-start;
        margin: 6px;
    }

    .peopleTitle {
        width: 120px;
    }

    .subcategories {
        gap: 6px;
    }
}

@media screen and (max-width: 704px) {
    .leftContainer {
        flex-direction: column;
    }

    .peopleTitle {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .subcategories {
        gap: 6px;
        margin-top: 10px;
    }

    .wrp {
        /*margin-top: 6px;*/
        padding-top: 10px;
        padding-bottom: 10px;
    }
}