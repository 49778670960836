.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    margin-bottom: 20px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    /*background-color: #D9D9D9;*/
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    width: 95%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.text {
    padding: 20px;
}